<template>
  <RightSideBar
    v-if="isOpen"
    @close="isOpen = false"
    close-button
    button-class="bg-dynamicBackBtn text-white"
    @submit="handleSubmit(onSubmit)"
    width="width:520px"
  >
    <template v-slot:title>
      <div class="flex justify-start items-center gap-0">
          <back-button @onClick="isOpen = false" variant="secondary" style="padding:0" />
          <span class="text-darkPurple font-bold text-lg">Days Back Request</span>
        </div>
    </template>
     <template v-slot:subtitle>
      <div class="flex flex-col divide-y divide-romanSilver divide-dashed">
        <div class="flex flex-col justify-start gap-5 pb-4">
          <div class="flex justify-between items-center">
            <p class="text-sm text-darkPurple font-normal mb-0">Employee Details</p>
            <div class="rounded-md p-2 text-sm w-28 text-center"
              :class="{
                'bg-green-100 text-mediumSeaGreen': leaveInfo.approvalStatus === 'approved',
                'bg-red-100 text-flame': leaveInfo.approvalStatus === 'disapproved',
                'bg-yellow-100 text-carrotOrange': leaveInfo.approvalStatus === 'pending'
              }"
            >
              {{
                leaveInfo.approvalStatus === 'approved' ? 'Approved':
                leaveInfo.approvalStatus === 'disapproved' ? 'Disapproved':
                leaveInfo.approvalStatus === 'pending' ? 'Pending' : ''
              }}
            </div>
          </div>
          <div class="w-full flex justify-start items-start gap-4">
            <div>
              <span v-if="employeeInfo.photo" class="flex justify-center items-center w-36 h-44">
                <img :src="employeeInfo.photo" class="w-36 h-44 rounded-md" alt="profile photo" />
              </span>
              <div v-else class="flex justify-center items-center w-36 h-44 rounded-md border">
                <span class="text-blueCrayola text-center font-semibold text-6xl p-2">
                  {{ $getInitials(`${employeeInfo.fname} ${employeeInfo.lname}`) }}
                </span>
              </div>
            </div>
            <div class="w-full flex flex-col justify-start items-start gap-1">
              <p class="font-bold text-lg text-darkPurple mb-0">
                {{ employeeInfo.fname}} {{ employeeInfo.lname }}
              </p>
              <div class="w-full flex jusity-start items-center gap-2">
                <span class="w-3/5 font-semibold text-sm text-romanSilver">Function:</span>
                <span class="w-2/5 font-semibold text-sm text-darkPurple">
                  {{employeeInfo.orgFunction &&  employeeInfo.orgFunction.name }}
                </span>
              </div>
              <div class="w-full flex jusity-start items-center gap-2">
                <span class="w-3/5 font-semibold text-sm text-romanSilver">Designation:</span>
                <span class="w-2/5 font-semibold text-sm text-darkPurple">
                  {{ employeeInfo.userDesignation && employeeInfo.userDesignation.name }}
                </span>
              </div>
              <div class="w-full flex jusity-start items-center gap-2">
                <span class="w-3/5 font-semibold text-sm text-romanSilver">Job Level:</span>
                <span class="w-2/5 font-semibold text-sm text-darkPurple">
                  {{ employeeInfo.userLevel && employeeInfo.userLevel.name }}
                </span>
              </div>
              <div class="w-full flex jusity-start items-center gap-2">
                <span class="w-3/5 font-semibold text-sm text-romanSilver">Office Location:</span>
                <span class="w-2/5 font-semibold text-sm text-darkPurple">
                  {{ employeeInfo.employeeOffice && employeeInfo.employeeOffice.name }}
                </span>
              </div>
              <div class="w-full flex jusity-start items-center gap-2">
                <span class="w-3/5 font-semibold text-sm text-romanSilver ">Manager:</span>
                <span v-if="employeeInfo.userReportingTo" class="w-2/5 font-semibold text-sm text-darkPurple">
                   {{ employeeInfo.userReportingTo.fname }} {{ employeeInfo.userReportingTo.lname }}
                </span>
              </div>
              <div class="w-full flex jusity-start items-center gap-2">
                <span class="w-3/5 font-semibold text-sm text-romanSilver">Length of Service:</span>
                <span class="w-2/5 font-semibold text-sm text-darkPurple">
                  {{ employeeInfo.lengthOfService }}
                </span>
              </div>
            </div>
          </div>
          <div class="flex flex-col justify-start gap-4">
            <div class="w-full flex flex-col justify-start items-start gap-2 pb-1">
              <span class="text-sm text-jet font-normal">Request Date</span>
              <div class="w-full bg-cultured border border-romanSilver rounded-md py-2 px-4">
                <span class="text-romanSilver text-base font-normal">
                  {{ $DATEFORMAT(new Date(leaveInfo.createdAt), 'MMMM dd, yyyy') }}
                </span>
              </div>
            </div>
            <div class="w-full flex flex-col justify-start items-start gap-2 pb-1">
              <span class="text-sm text-jet font-normal">Approved Leave Duration</span>
              <div class="w-full flex justify-start gap-1">
                <div class="w-full bg-cultured border border-romanSilver rounded-md py-2 px-4">
                  <span class="text-romanSilver text-base font-normal">
                    {{ $DATEFORMAT(new Date(leaveInfo.requestStartDate), 'MMM dd, yyyy') }} to
                    {{ $DATEFORMAT(new Date(leaveInfo.requestEndDate), 'MMM dd, yyyy') }}
                  </span>
                </div>
                <div class="w-4/12 bg-cultured border border-romanSilver rounded-md py-2 px-4">
                  <span class="text-romanSilver text-base font-normal">
                    <span class="text-romanSilver text-base font-normal">
                      {{ leaveInfo.leaveRequest.daysRequested }} Day(s)
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div class="w-full flex flex-col justify-start items-start gap-2 pb-1">
              <span class="text-sm text-jet font-normal">Total Days Back Request</span>
              <div class="w-full bg-cultured border border-romanSilver rounded-md py-2 px-4">
                {{ leaveInfo.daysRequested }} Day(s)
              </div>
            </div>
            <div>
              <template v-for="item in leaveInfo.leaveBackRequestDetails">
                <div :key="JSON.stringify(item)" class="flex justify-between items-center gap-4">
                  <div class="w-full flex flex-col justify-start items-start gap-2 pb-1">
                    <span class="text-sm text-jet font-normal">Days</span>
                    <div class="w-full bg-cultured border border-romanSilver rounded-md py-2 px-4">
                      <span class="text-romanSilver text-base font-normal">
                        {{ $DATEFORMAT(new Date(item.requestDate), 'MMMM dd, yyyy') }}
                      </span>
                    </div>
                  </div>
                  <div class="w-full flex flex-col justify-start items-start gap-2 pb-1">
                    <span class="text-sm text-jet font-normal">Justification</span>
                    <div class="w-full bg-cultured border border-romanSilver rounded-md py-2 px-4">
                      <span class="text-romanSilver text-base font-normal">{{ item.justification }}</span>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </template>
  </RightSideBar>
</template>

<script>
import { differenceInDays } from "date-fns"
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button"
import RightSideBar from "@/components/RightSideBar"

export default {
  components: {
    BackButton,
    RightSideBar
  },
  data(){
    return {
      differenceInDays,

      isOpen: false,
      isLoading: false,
      employeeInfo: {},
      leaveInfo: {},
    }
  },
  methods: {
    onOpen({leaveInfo, employeeInfo}){
      this.leaveInfo = leaveInfo
      this.employeeInfo = employeeInfo
      this.isOpen = true
    },
    onSubmit(){}
  }
}
</script>